import {request, prefix} from "./request";

// 获取订单列表
export function GetOrderList(data: any) {
    return request({
        url: prefix + 'GetOrderList',
        method: 'post',
        data,
        showLoading: true,
    })
}

// 获取客户各订单状态总数
export function GetCustomerOrdersStatusTotal() {
    return request({
        url: prefix + 'GetCustomerOrdersStatusTotal',
        method: 'post',
    })
}

// 取消订单
export function CancelOrder(data: any) {
    return request({
        url: prefix + 'CancelOrder',
        method: 'post',
        data,
        showLoading: true,
    })
}

// 确认收货
export function OrderDeliver(data: any) {
    return request({
        url: prefix + 'OrderDeliver',
        method: 'post',
        data,
        showLoading: true,
    })
}


// 单笔订单详情信息
export function GetOrderDetailInfo(data: any) {
    return request({
        url: prefix + 'GetOrderDetailInfo',
        method: 'post',
        data,
        showLoading: true,
    })
}

// 申请取消订单
export function ApplyCancelOrder(data: any) {
    return request({
        url: prefix + 'ApplyCancelOrder',
        method: 'post',
        data,
        showLoading: true,
    })
}

// 确认订单
export function ConfirmOrder(data: any) {
    return request({
        url: prefix + 'ConfirmOrder',
        method: 'post',
        data,
        showLoading: true,
    })
}

// 恢复订单并设置处理中
export function RecoverOrder(data: any) {
    return request({
        url: prefix + 'RecoverOrder',
        method: 'post',
        data,
        showLoading: true,
    })
}

// 给出等待确认的订单
export function GetWaitConfirmOrderInfo(data: any) {
    return request({
        url: prefix + 'GetWaitConfirmOrderInfo',
        method: 'post',
        data,
        showLoading: true,
    })
}

// 给出等待确认的订单
export function VerifyProductIsBuyNow(data: any) {
    return request({
        url: prefix + 'VerifyProductIsBuyNow',
        method: 'post',
        data,
        showLoading: true,
    })
}

// 订单的所需运费金额
export function GetOrderShippingAmount(data: { addressId: number, productInfo?: string }) {
    return request({
        url: prefix + 'GetOrderShippingAmount',
        method: 'post',
        data,
        showLoading: true,
    })
}

//微信H5支付（浏览器中使用）
export function WeixinH5Payment(data: any) {
    return request({
        url: prefix + 'WeixinH5Payment',
        method: 'post',
        data,
        showLoading: true,
    })
}

//支付宝H5支付
export function AliPayH5Payment(data: any) {
    return request({
        url: prefix + 'AliPayH5Payment',
        method: 'post',
        data,
        showLoading: true,
    })
}

// 微信公众号JS支付
export function WeixinJSPayment(data: any) {
    return request({
        url: prefix + 'WeixinJSPayment',
        method: 'post',
        data,
        showLoading: true,
    })
}

// 选择订单支付方式（页面接口)
export function SelectOrderPaymentMethod(data: any) {
    return request({
        url: prefix + 'SelectOrderPaymentMethod',
        method: 'post',
        data,
        showLoading: true,
    })
}

// 修改订单支付方式（最主要是货到到付款使用）
export function ChangePaymentMethod(data: any) {
    return request({
        url: prefix + 'ChangePaymentMethod',
        method: 'post',
        data,
        showLoading: true,
    })
}

// 订单是否已支付
export function IsOrderPaid(data: any) {
    return request({
        url: '/API/Order/IsOrderPaid',
        method: 'post',
        data,
        showLoading: true,
    })
}

// 获取订单的待评价的产品
export function GetCustomerWaitReviewOrderProducts(data: any) {
    return request({
        url: '/API/Order/GetCustomerWaitReviewOrderProducts',
        method: 'post',
        data,
    })
}
// 获取已评论的订单产品列表
export function GetCustomerOrderProductReviews(data: any) {
    return request({
        url: '/API/Order/GetCustomerOrderProductReviews',
        method: 'post',
        data,
    })
}
// 获取等待评论的订单（不包含已评价的产品）
export function GetCustomerWaitReviewOrders(data: any) {
    return request({
        url: '/API/Order/GetCustomerWaitReviewOrders',
        method: 'post',
        data,
    })
}
// 添加订单产品评价/追评
export function AddOrderProductReview(data: any) {
    return request({
        url: '/API/Order/GetCustomerWaitReviewOrderProducts',
        method: 'post',
        data,
    })
}
// // 获取订单的待评价的产品
// export function GetCustomerWaitReviewOrderProducts(data: any) {
//     return request({
//         url: '/API/Order/GetCustomerWaitReviewOrderProducts',
//         method: 'post',
//         data,
//     })
// }
