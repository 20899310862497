import { useEffect, useLayoutEffect, useRef } from "react";
import { useRoutes, useLocation } from "react-router-dom";
// import { routeConfig } from './routes';
import { useDispatch, useSelector } from "react-redux";
import { CustomerUnreadMessageTotals, getSetting } from './redux/home'
import { getCart } from "./redux/cart";
import VConsole from "vconsole";
import { Toast } from "antd-mobile";
import { FootPrint } from "./api/commom";
import { checkDev, loadQykf, setShare, Subscriptions } from "./utils";
import { wxLogin } from "./redux/login";
import { GetCustomerInfoSlience } from "./api/login";
import { useNavigate } from "react-router";
export let preUrl: string = ''
export const globalSub = new Subscriptions();
export const App = () => {
    const login = useSelector(state => state.login)
    const cart = useSelector(state => state.cart)
    const location = useLocation();
    const dispatch = useDispatch();
    // const preUrl = useRef<string>('');
    const navigate = useNavigate();


    useLayoutEffect(() => {
        globalSub.on('navigate', (url: string) => {
            navigate(url)
        })
        globalSub.on('redirect', (url: string) => {
            navigate(url, { replace: true })
        })
    })
    useEffect(() => {
        // dispatch({type: 'setting/night'});
        // 偷偷检测有没有登陆
        GetCustomerInfoSlience().then(userRes => {
            if (userRes.DataValue) {
                dispatch({ type: 'login/wx', payload: { CustomerInfo: userRes.DataValue } })
            }
        })
        // 如果没有用户信息就先把用户信息清除掉
        if (!login.CustomerInfo) dispatch({ type: 'login/out' });
        // 如果是微信内打开，去授权
        if (navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1 && !login.CustomerInfo) dispatch(wxLogin());
        // 获取商城配置
        dispatch(getSetting());
        // 如果登录了
        if (!!login.CustomerInfo?.Phone) {
            // 获取购物车
            // if (cart.cartList.length <= 0) {
            dispatch(getCart());
            // }
            // 获取未读信息
            dispatch(CustomerUnreadMessageTotals());
        }
        // 加载七鱼客服
        loadQykf();
        // clearInLocal();
        // @ts-ignore
        if (!!window.wx) {
            setShare();
        }
    }, [])

    useEffect(() => {
        if (window.document.URL === preUrl) return;
        FootPrint({ referrerUrl: preUrl, currentUrl: window.document.URL });
        // @ts-ignore
        // window.referrerUrl = preUrl.current ? preUrl.current : window.document.URL
        setTimeout(() => {
            preUrl = window.document.URL;
        }, 10)
        if (checkDev.isTea7App) adapterApp(location.pathname)
    }, [location])

    // const vConsole = new VConsole();
    useEffect(() => {
        if (login.isNight) {
            document.body.classList.add("dark-mode")
        }
        if (login.openDev) {
            const vConsole = new VConsole();
        }
        if (!!login.CustomerInfo) {
            cart.cartList.length <= 0 && dispatch(getCart());
        }
    }, [login])

    setTimeout(() => {
        var box = document.getElementById("mask-loading");
        box && box.remove();
    }, 10)


    function adapterApp(url: string) {
        // 购物车
        if (url === '/cart') {
            // @ts-ignore
            if (checkDev.isAndroid) return window.tea7func.androidMethod_OpenShoppingCart();
            // @ts-ignore
            window.webkit.messageHandlers.tea7func.postMessage("OpenShoppingCart")
        } else if (url === '/my') {
            // @ts-ignore
            if (checkDev.isAndroid) return window.tea7func.androidMethod_OpenCustomeerHome();
            // @ts-ignore
            window.webkit.messageHandlers.tea7func.postMessage("OpenCustomeerHome")
        } else if (url === '/order/confirm') {
            // @ts-ignore
            if (checkDev.isAndroid) return window.tea7func.androidMethod_ConfirmOrder();
            // @ts-ignore
            window.webkit.messageHandlers.tea7func.postMessage("ConfirmOrder")
        }
    }


    return (<></>)
};

