import {map} from 'ramda';
import React, {Suspense, useLayoutEffect} from 'react';
import {Route, Routes, BrowserRouter} from 'react-router-dom';
import {routes} from './configure';
import {App} from '../App'
import {AaOutline} from 'antd-mobile-icons';
import {Subscriptions} from "../utils";
import LoadingBox from "../components/loading-box/loading-box";


export const SyncRouter: React.FC = () => {
    return (
        <BrowserRouter>
            <Suspense fallback={<LoadingBox/>}>
                <Routes>
                    {map((route) => (
                        <Route path={route.path} key={route.name} element={<route.component route={route}/>}/>
                    ), routes)}
                </Routes>
                <App/>
            </Suspense>
        </BrowserRouter>
    )
}