/**
 * @By WebStorm
 * @Description:
 * @author cmk
 * @date 2022/1/7 16:35
 */
import thunkMiddleware from 'redux-thunk';
import {applyMiddleware, combineReducers, createStore} from "redux";
import login from './login'
import jump from './jump'
import cart from './cart'
import order from './order'
import home from "./home";
import {persistCombineReducers,persistStore,persistReducer} from 'redux-persist'
import storage from 'redux-persist/es/storage';

let config={
    key: 'root',
    storage,
}

const reducers = persistCombineReducers(config,{
    login,
    jump,
    cart,
    home,
    order
})

export let store=createStore(reducers, applyMiddleware(thunkMiddleware));
// 持久化处理
export let persistor=persistStore(store)

export type AppState = ReturnType<typeof reducers>
declare module 'react-redux' {
    interface DefaultRootState extends AppState {
    }
}
