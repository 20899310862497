import {DotLoading, Toast} from "antd-mobile";
import {useEffect, useState} from "react";


export default function LoadingBox() {
    useEffect(()=>{
        // Toast.clear();
    },[])
    return (
        <div style={{display: 'flex',paddingTop:'4rem', justifyContent: 'center', width: '100vw', height:"100vh",position:'relative',left:0,top:0,zIndex:9999,backgroundColor:'#ffffff', }}>
            <img src="http://m.tea7.com/images/imgLoading2.gif" style={{width:'5rem',height:'5rem'}} alt=""/>
        </div>)
}