import { prefix, request } from "./request";

// 账号密码登陆
export async function loginjson(data: any) {
    return request({
        url: prefix + 'PasswordLoginForToken',
        method: "post",
        data,
        showLoading: true,
        isForm: true,
    })
}

// 账号密码登陆
export async function PasswordLogin(data: any) {
    return request({
        url: prefix + 'PasswordLogin',
        method: "post",
        data,
        showLoading: true,
        isForm: true,
    })
}

// 绑定手机号，返回手机号登录用户（存在合并已存在的手机号账户）
export async function BindPhoneNumber(data: any) {
    return request({
        url: '/API/Customer/BindPhoneNumber',
        method: "post",
        data,
        showLoading: true,
    })
}

// 获取验证码
export async function getValidation(data: any) {
    return request({
        url: 'customer/CheckValidationSMS',
        method: "post",
        data,
        showLoading: true,
        // isForm:true,
    })
}

// 获取微信登陆状态
export async function CurrentCustomerIsBindWexin() {
    return request({
        url: '/Json/CurrentCustomerIsBindWexin/',
        method: "post",
        // isForm:true,
    })
}

// 获取客户账户概况（收藏数，茶币等信息）
export async function GetCustomerAccountOverview() {
    return request({
        url: prefix + '/GetCustomerAccountOverview',
        method: "post",
        showLoading: true,
        // isForm:true,
    })
}

// 获取客户基础信息
export async function GetCustomerInfo() {
    return request({
        url: prefix + '/GetCustomerInfo',
        method: "post",
        showLoading: true,
        // isForm:true,
    })
}

// 发送短信验证码（需要图形验证码）
export async function SendSMSVerifyCode(data: any) {
    return request({
        url: '/API/Customer/SendSMSVerifyCode',
        method: "post",
        showLoading: true,
        data,
        isForm: true,
    })
}

// 获取客户基础信息
export async function GetCustomerInfoSlience() {
    return request({
        url: prefix + '/GetCustomerInfo',
        method: "post",
        slience: true,
    })
}

// 短信登录（返回Token）
export async function SmsLoginForToken(data: any) {
    return request({
        url: prefix + '/SmsLoginForToken',
        method: "post",
        showLoading: true,
        data,
        isForm: true
    })
}

// 短信登录（返回Token）
export async function SmsLogin(data: any) {
    return request({
        url: prefix + '/SmsLogin',
        method: "post",
        showLoading: true,
        data,
        isForm: true
    })
}

// 保存客户个人资料信息
export async function SaveCustomerProfile(data: any) {
    return request({
        url: '/API/Customer/SaveCustomerProfile',
        method: "post",
        showLoading: true,
        data
    })
}

// 登出操作/退出账户
export async function SaveCustomerAvatar(data: any) {
    return request({
        url: '/API/Customer/SaveCustomerAvatar',
        method: "post",
        showLoading: true,
        data
    })
}

// 获取客户个人资料信息
export async function GetCustomerProfile() {
    return request({
        url: '/API/Customer/GetCustomerProfile',
        method: "post",
        showLoading: true,
    })
}

// 登出操作/退出账户
export async function SignOut() {
    return request({
        url: prefix + '/SignOut',
        method: "post",
        showLoading: true,
        isForm: true
    })
}
