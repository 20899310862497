/**
 * @By WebStorm
 * @Description: 订单处理
 * @author cmk
 * @date 2022/2/11 14:47
 */
import { ResultData } from "../schemas/commom";
import { history } from "../routes/history";
import { store } from './index'
import { Toast } from "antd-mobile";
import { payTypeList } from "../pages/order/types/payType";
import { globalSub } from "../App";
export interface OrderInfo {
    currentProductObj?: string,
    currentSubmitObj?: string,
}

const defaultOrder: OrderInfo = {
    currentProductObj: undefined,
    currentSubmitObj: undefined,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state: OrderInfo = defaultOrder, action?: any) {
    switch (action.type) {
        case 'order/set':
            return Object.assign({}, {
                currentProductObj: action.payload.currentProductObj,
                currentSubmitObj: action.payload.currentSubmitObj
            })
        case 'order/del':
            return defaultOrder
        case 'order/submit':

        // eslint-disable-next-line no-fallthrough
        default:
            return state
    }
}

export function toPayPage(orderId: string, key: string) {
    // history.replace((`/payResult/${orderId}::${key}`))
    globalSub.emit('redirect', `/payResult/${orderId}::${key}`)
}

// 支付处理
export function payManage(orderId: string, fn?: (data: any) => Promise<ResultData<any>>,) {
    // 如果没有传支付方法，那就是货到付款
    const payList = payTypeList();
    // if (!!fn) {
    //     fn({
    //         orderId,
    //         openId: store.getState().login.CustomerInfo && store.getState().login.CustomerInfo.OpenId || '',
    //     }).then(res => {
    //         // if (typeof res.DataValue === 'string') {
    //         // window.location.href = res.DataValue;
    //         // }
    //         setTimeout(() => {
    //             history.replace(`/payResult/${orderId}`)
    //         }, 700)
    //     })
    // } else {
    //     // 货到付款
    //     Toast.show({
    //         icon: 'success',
    //         content: '提交成功！',
    //         afterClose: () => {
    //             history.replace(`/payResult/${orderId}?isHdfk=1`)
    //         }
    //     });
    // }
}