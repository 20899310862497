/**
 * @By WebStorm
 * @Description: 用于处理跳转逻辑的Reducer
 * @author cmk
 * @date 2022/1/9 21:29
 */
// import {hashHis}
import {GuessItem} from "../pages/home/components/fall-layout";
import {baseUrl, isDev} from "../api/request";
import {store} from '../redux/index';
import {Toast} from "antd-mobile";
import {checkDev, Subscriptions} from "../utils";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {createPortal} from "react-dom";
import {globalSub} from "../App";

function guessRouter(item: GuessItem) {
    if (item.ShowType === 'Product') {
        globalSub.emit('navigate', '/product/' + item.Data.Id)
    } else {
        window.location.href = <string>item.Data.Url
    }
}


export default function Jumps(state: any, action: any) {
    switch (action.type) {
        case 'jump/url':
            // history.push(action.payload);
            break;
        case 'jump/guesslike':
            guessRouter(action.payload)
            return {}
        case 'jump/product':
            if (checkDev.isTea7App) {
                if (checkDev.isAndroid) {
                    // @ts-ignore
                    window.tea7func.androidMethod_OpenProduct(action.payload.id);
                } else {
                    // @ts-ignore
                    window.webkit.messageHandlers.tea7func.postMessage("OpenProduct_" + action.payload.id + "");
                }
                return
            }
            globalSub.emit('navigate', '/product/' + action.payload.id)
            // navigate('/product/' + action.payload.id);
            // window.location.href = '/product/' + action.payload.id
            return state
        case 'jump/service':
            var ua = navigator.userAgent;
            if (ua.indexOf("T7appAndroidV2") >= 0) {
                // @ts-ignore
                window.tea7func.androidMethod_OpenContactKF && window.tea7func.androidMethod_OpenContactKF(window.location.href, document.title);
            } else {
                // @ts-ignore
                // window.ysf.open();
                // 跳转到微信客服
                window.location.href = 'https://work.weixin.qq.com/kfid/kfce41e350dae7b0017';
            }
            return;
        case 'jump/myService':
            if (!!store.getState().login.CustomerInfo) {
                window.location.href = baseUrl + '/UserCenter/MyCustomerService'
            } else {
                globalSub.emit('navigate', '/login')
            }
            return;
        case 'jump/cart':
            if (checkDev.isTea7App) {
                if (checkDev.isAndroid) {
                    // @ts-ignore
                    window.tea7func.androidMethod_OpenShoppingCart();
                } else {
                    // @ts-ignore
                    window.webkit.messageHandlers.tea7func.postMessage("OpenShoppingCart");
                }
                return
            }
            globalSub.emit('navigate', '/cart');
            break
        case 'jump/tzlb':
            window.location.href = baseUrl + '/zt/2021/tzlb/index.html'
            return
        case 'jump/todayMs':
            window.location.href = baseUrl + '/zt/2021/todayseckill/index.html'
            return;
        default:
            return {}
    }
}