import {request,prefix} from "./request";

// 获取购物车信息
export async function getCustomerShoppingCart(){
    return request({
        url:prefix+'GetCustomerShoppingCart',
        method:'post',
        showLoading:true,
    })
}
// 获取客户购物车产品总数
export async function GetCustomerShoppingCartProductTotal(){
    return request({
        url:prefix+'GetCustomerShoppingCartProductTotal',
        method:'post',
        showLoading:true,
    })
}

// 更改购物车的数量并返回购物车选中产品统计信息
export async function UpdateShoppingCartItemQuantityReturnResult(data:any){
    return request({
        url:prefix+'UpdateShoppingCartItemQuantityReturnResult',
        method:"post",
        data,
        showLoading:true,
    })
}
// 更改购物车的数量
export async function UpdateShoppingCartItemQuantity(data:any){
    return request({
        url:prefix+'UpdateShoppingCartItemQuantity',
        method:"post",
        data,
        showLoading:true,
    })
}
// 获取购物车选中产品的统计信息
export async function GetShoppingCartSelectedStatisticsInfo(){
    return request({
        url:prefix+'GetShoppingCartSelectedStatisticsInfo',
        method:"post",
        showLoading:true,
    })
}
// 更改购物车的选中状态
export async function UpdateShoppingCartItemSelected(data:any){
    return request({
        url:prefix+'UpdateShoppingCartItemSelected',
        method:"post",
        data,
        showLoading:true,
    })
}

// 更改购物车的选中状态并返回购物车选中产品统计信息
export async function UpdateShoppingCartItemSelectedAndReturnResult(data:any){
    return request({
        url:prefix+'UpdateShoppingCartItemSelectedAndReturnResult',
        method:"post",
        data,
        showLoading:true,
    })
}

// 加入购物车（返回购物车总数）
export async function AddShoppingCart(data:any){
    return request({
        url:prefix+'AddShoppingCart',
        method:"post",
        data,
        showLoading:true,
    })
}
// 加入购物车（返回购物车总数）
export async function GetCustomerShoppingCartTotal(data?:any){
    return request({
        url:prefix+'GetCustomerShoppingCartTotal',
        method:"post",
        data,
        showLoading:true,
    })
}
// 加入购物车（返回购物车项编号）
export async function AddShoppingCartReturnItemId(data:any){
    return request({
        url:prefix+'AddShoppingCartReturnItemId',
        method:"post",
        data,
        showLoading:true,
    })
}

// 移除购物车商品
export async function RemoveShoppingCartItem(data:any){
    return request({
        url:prefix+'RemoveShoppingCartItem',
        method:"post",
        data,
        showLoading:true,
    })
}

// 购买购物车中的选中产品（购物车中点去结算的时候调用）
export async function BuyShoppingCartProductNow(data:any){
    return request({
        url:prefix+'BuyShoppingCartProductNow',
        method:"post",
        data,
        showLoading:true,
        toastText:'校验中'
    })
}


