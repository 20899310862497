import {EnumBooleanMember} from '@babel/types';
import {lazy} from 'react';
import PageNotFount from '../layout/404';

export type Component = React.ComponentType<any> | React.LazyExoticComponent<any>

export interface RouterConfig {
    path: string,
    models?: () => Array<PromiseLike<any>>,
    component: Component,
    exact?: boolean,
    name: string,
    icon?: Component,
    noCache?: boolean,
    noTags?: boolean,
    children?: Array<this>,
    meta?: {
        title: string,
        // 是否禁用标题栏 默认是
        disableTitle?: boolean,
        // 是否禁用搜索栏 默认否
        enableSearch?: boolean,
        // 是否启用tabbar 默认否
        enableTabbar?: boolean,
        // 是否显示返回按钮
        showBackBtn?: boolean
    },
    // 是否需要登陆
    login?: boolean,
    // 重定向
    redirect?: string,
}

// 首页路由
const HomeRoutes: Array<RouterConfig> = [
    {
        path: "home",
        component: lazy(() => import('../pages/home/index')),
        name: "首页",
        meta: {
            title: "首页",
            disableTitle: true,
            enableSearch: true,
            enableTabbar: true
        }
    }, {
        path: 'category',
        component: lazy(() => import('../pages/category')),
        name: "分类",
        meta: {
            title: "分类",
            disableTitle: true,
            enableTabbar: true
        }
    }, {
        path: "cart",
        component: lazy(() => import('../pages/cart/index')),
        name: "购物车",
        meta: {
            title: "购物车",
            disableTitle: true,
            enableTabbar: true
        }
    }, {
        path: '/my',
        component: lazy(() => import('../pages/my/index')),
        name: "我的",
        meta: {
            title: "我的",
            disableTitle: true,
            enableTabbar: true
        }
    }, {
        path: "/product/:id",
        component: lazy(() => import('../pages/product/index')),
        name: "商品详情",
        meta: {
            title: "商品详情",
            disableTitle: true,
            showBackBtn: true
        }
    }, {
        path: "/selfdrink",
        component: lazy(() => import('../pages/module/self-drink')),
        name: "自饮茶",
        meta: {
            title: '自饮茶',
        }
    }, {
        path: "/JiangrenTea",
        component: lazy(() => import('../pages/module/jiangren-tea')),
        name: "匠人茶",
        meta: {
            disableTitle: true,
            title: '匠人茶',
        },
        children: [
            {
                path: ":id",
                component: lazy(() => import('../pages/module/jiangren-tea')),
                name: "匠人茶",
                meta: {
                    disableTitle: true,
                    title: '匠人茶',
                },
            }
        ]
    }, {
        path: "/matchtea",
        component: lazy(() => import('../pages/module/match-tea')),
        name: "赛事茶",
        meta: {
            disableTitle: true,
            title: '赛事茶',
        },
        children: [
            {
                path: ":id",
                component: lazy(() => import('../pages/module/match-tea')),
                name: "赛事茶",
                meta: {
                    disableTitle: true,
                    title: '赛事茶',
                },
            }
        ]
    }, {
        path: "/brandtea",
        component: lazy(() => import('../pages/module/brand-tea')),
        name: "品牌茶",
        meta: {
            // disableTitle: true,
            title: '品牌茶',
        },
        children: [
            {
                path: ":id",
                component: lazy(() => import('../pages/module/brand-tea')),
                name: "品牌茶",
                meta: {
                    disableTitle: true,
                    title: '品牌茶',
                },
            }
        ]
    }, {
        name: "登陆",
        path: "/login",
        component: lazy(() => import('../pages/login/index')),
        meta: {
            title: "登陆",
        }
    }, {
        path: "/bindPhone",
        name: "绑定手机",
        component: lazy(() => import('../pages/login/bind-phone')),
        meta: {
            title: "登陆"
        }
    },
    {
        name: '茶具',
        path: "/modulepot/:type",
        component: lazy(() => import('../pages/module/module-pot')),
        meta: {
            title: "茶具",
            disableTitle: true
        }
    },
    {
        name: '茶具',
        path: "/modulepot",
        component: lazy(() => import('../pages/module/module-pot')),
        meta: {
            title: "茶具",
            disableTitle: true
        }
    }, {
        name: "发表评价",
        path: "/comment/:id",
        component: lazy(() => import('../pages/my/comment')),
        meta: {
            title: "发表评价"
        }
    }, {
        name: "我的评价",
        path: "/commentlist",
        component: lazy(() => import('../pages/order/comment-list')),
        meta: {
            title: "我的评价"
        }
    },
    {
        name: "收藏列表",
        path: "/collectionList",
        component: lazy(() => import('../pages/my/collection-list')),
        meta: {
            title: "收藏列表",
        }
    }, {
        name: "会员中心",
        path: '/vip',
        component: lazy(() => import('../pages/my/vip')),
        meta: {
            title: "会员中心",
            disableTitle: true,
            showBackBtn: true
        }
    },
    {
        name: "我的足迹",
        path: "/footprint",
        component: lazy(() => import('../pages/my/footprint')),
        meta: {
            title: "足迹"
        }
    }, {
        path: "/homepot",
        name: "茶具",
        component: lazy(() => import('../pages/module/home-pot')),
    }, {
        path: "/coupon",
        name: "我的优惠券",
        component: lazy(() => import('../pages/coupon/index')),
        meta: {
            title: "我的优惠券",
        }
    },
    {
        path: "/setting",
        name: "设置",
        component: lazy(() => import('../pages/my/setting')),
        meta: {
            title: "设置",
        }
    }, {
        path: "/addressList",
        name: "地址列表",
        component: lazy(() => import('../pages/my/address-list')),
        meta: {
            title: "地址列表",
        }
    }, {
        path: "/addressDetail/:id",
        name: "编辑地址",
        component: lazy(() => import('../pages/my/address-detail')),
        meta: {
            title: "编辑地址",
        }
    }, {
        path: "/addressDetail",
        name: "添加地址",
        component: lazy(() => import('../pages/my/address-detail')),
        meta: {
            title: "添加地址",
        }
    }, {
        path: "/payResult/:orderId",
        name: "支付结果",
        component: lazy(() => import('../pages/order/payResult')),
        meta: {
            title: "支付结果",
        }
    }, {
        path: "/personEdit",
        name: "个人信息",
        component: lazy(() => import('../pages/my/person-info')),
        meta: {
            title: "编辑个人信息",
        }
    }, {
        path: "/categoryDetail/:typeid",
        name: "分类",
        component: lazy(() => import('../pages/category/result')),
        meta: {
            title: "分类",
        }
    }, {
        path: "/payResult/:orderId",
        name: "支付结果",
        component: lazy(() => import('../pages/order/payResult')),
        meta: {
            title: "支付结果",
        }
    }, {
        path: "/search",
        name: "搜索",
        component: lazy(() => import('../pages/search/index')),
        meta: {
            title: "搜索",
            disableTitle: true
        },
    }, {
        path: "/search/result",
        name: "搜索结果",
        component: lazy(() => import('../pages/search/result')),
        meta: {
            title: "搜索结果",
            disableTitle: true
        },
    }, {
        path: "/order/:type",
        name: "订单列表",
        component: lazy(() => import('../pages/order/index')),
        meta: {
            title: "订单列表",
        },
    }, {
        path: "/order",
        name: "订单列表",
        component: lazy(() => import('../pages/order/index')),
        meta: {
            title: "订单列表",
        },
    }, {
        path: "/order/confirm",
        name: "提交订单",
        component: lazy(() => import('../pages/order/submit-order')),
        meta: {
            title: "提交订单",
            // disableTitle: true
        }
    }, {
        path: "/order/selectPay/:id",
        name: "选择支付方式",
        component: lazy(() => import('../pages/order/select-pay')),
        meta: {
            title: "选择支付方式",
            // disableTitle: true
        }
    }, {
        path: "/order/detail/:id",
        name: "订单详情",
        component: lazy(() => import('../pages/order/detail')),
        meta: {
            title: "订单详情",
        }
    },
    {
        path: "/order:type",
        name: "订单列表",
        component: lazy(() => import('../pages/order/index')),
        meta: {
            title: "订单列表",
        }
    }, {
        path: "/potsearch",
        name: "茶具",
        component: lazy(() => import('../pages/search/pot-search')),
        meta: {
            title: "",
        }
    }, {
        path: "/live/:id",
        name: "直播",
        component: lazy(() => import('../pages/live/index')),
        meta: {
            title: "直播",
            disableTitle: true,
            showBackBtn: true
        }
    }
]

// 页面路由
const otherRoutes: Array<RouterConfig> = [
    {
        path: "/",
        component: lazy(() => import('../layout/home-layout')),
        name: '首页',
        children: HomeRoutes,
    },
]

export const routes: Array<RouterConfig> = [
    {
        path: '/404',
        component: lazy(() => import('../layout/404')),
        // component: PageNotFount,
        name: '找不到页面~'
    },
    {
        path: '/*',
        component: lazy(() => import('../components/app-cache/index')),
        name: '茶七网',
        children: HomeRoutes,
    }
]
