import axios from "axios";
import { ResultData } from "../schemas/commom";
import { Toast } from "antd-mobile";
import Qs from "qs";
import { store } from "../redux";
import { globalSub } from "../App";

axios.defaults.withCredentials = true;

export const baseUrl = process.env.NODE_ENV === 'development' ? (document.location.protocol + '//mt.tea7.com/') : (document.location.protocol + '//m.tea7.com/');
// export const isDev = process.env.NODE_ENV !== 'development';

// export const baseUrl = document.location.protocol === 'https:' ? 'https://m.tea7.com/' : 'http://m.tea7.com/';
// export const baseUrl = process.env.NODE_ENV === 'development' ? 'http://mt.tea7.com/' : 'http://m.tea7.com/';
// export const baseUrl = "http://mt.tea7.com/";
export const isDev = true;

export const prefix = "/API/ServicesOtherPlatforms/";

declare module "axios" {
  interface AxiosInstance {
    (config: AxiosRequestConfig): Promise<any>;
  }
}

interface requestConfig {
  // dddd
  url: string;
  // dddd
  method: "post" | "get";
  // 是否显示Loading
  showLoading?: boolean;
  // 是否表单
  isForm?: boolean;
  data?: any;
  // 是否自定义消息
  customToast?: boolean;
  // 自定义提示消息
  toastText?: string;
  // 失败成功均不显示任何弹窗
  slience?: boolean;
  // 是否是文件
  isFile?: boolean;
}

export const request = (config: requestConfig): Promise<ResultData<any>> => {
  let toastText = "加载中";
  if (!!config.toastText) toastText = config.toastText;
  if (config.showLoading === true)
    Toast.show({
      icon: "loading",
      content: toastText,
      duration: 0,
    });
  // if(config.data&&config.data.page&&config.data?.page===1)Toast.clear();
  let token: string | null = "",
    tokenKey: string | null = "";
  const headers: any = {
    // 'Content-Type': 'application/json'
    // 'Content-Type': 'application/json;charset=UTF-8'
    // 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  };
  token = store.getState().login.AccessToken;
  tokenKey = store.getState().login.AccessTokenName;

  if (!!token && !!tokenKey) {
    headers[tokenKey] = token;
  }

  if (config.isForm) {
    headers["Content-Type"] = "application/x-www-form-urlencoded";
    config.data = Qs.stringify(config.data);
  } else if (config.isFile) {
    headers["Content-Type"] = "multipart/form-data";
  }
  const http = axios.create({
    baseURL: baseUrl,
    headers: headers,
    withCredentials: true,
  });

  // 添加请求拦截器
  axios.interceptors.request.use(
    function (config) {
      // 在发送请求之前做些什么
      return config;
    },
    function (error) {
      // 对请求错误做些什么
      return Promise.reject(error);
    }
  );

  http.interceptors.response.use(
    function (response) {
      // 对响应数据做点什么
      Toast.clear();
      if (response.status === 200) {
        if (
          (response.data.Info === "签名失败" || response.data.Code === 601) &&
          !config.slience
        ) {
          Toast.show("请先登录");
          window.localStorage.clear();
          setTimeout(() => {
            globalSub.emit(
              "navigate",
              "/login?redirct=" + encodeURIComponent(window.location.pathname)
            );
          }, 800);
          throw new Error("没有登陆");
        }
        if (response.data.Code === 603 && !config.slience) {
          Toast.show("为了您的用户体验，请绑定手机号");
          setTimeout(() => {
            globalSub.emit(
              "navigate",
              "/bindPhone?redirct=" +
                encodeURIComponent(window.location.pathname)
            );
          }, 1200);
          throw new Error("没有绑定手机号");
        }
        if (response.data.Success) {
          return Object.assign(
            {},
            response.data,
            response.data?.DataValue
              ? replaceParams(response.data.DataValue)
              : {}
          );
        } else {
          if (!config.customToast && !config.slience) {
            if (response.data.message || response.data.Info) {
              Toast.show(
                response.data.message ||
                  response.data.Info ||
                  "系统异常，请联系客服人员"
              );
            }
          }
          return Promise.reject(response);
        }
      }
      return response.data;
    },
    function (error) {
      if (config.slience) return;
      if (!!error) Toast.show(error);
      // 对响应错误做点什么
      // if (window.location.pathname !== '/offline') history.replace('/offline?redirct=' + encodeURIComponent(window.location.pathname));
      return Promise.reject(error);
    }
  );

  return http(config);
};
// string
// number
// array
// object
// null
function replaceParams(obj: any) {
  // console.log('=========',Object.prototype.toString.call(obj))
  if (document.location.protocol !== "https:") return obj;
  if (Object.prototype.toString.call(obj) === "[object String]") {
    return obj.replace(/http:/g, "https:");
  } else if (Object.prototype.toString.call(obj) === "[object Array]") {
    let list = obj;
    list.forEach((element: any) => {
      element = replaceParams(element);
    });
    return list;
  } else if (Object.prototype.toString.call(obj) === "[object Object]") {
    let tempObj = obj;
    for (var key in tempObj) {
      tempObj[key] = replaceParams(tempObj[key]);
    }
    return tempObj;
  } else {
    return obj;
  }
}
