import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './theme/styles/theme.scss';
import { store, persistor } from './redux'
import { Toast } from 'antd-mobile';
import 'antd-mobile/es/global'
import { SyncRouter } from './routes';
import { PersistGate } from "redux-persist/integration/react";

// antd Toast配置
Toast.config({ maskClickable: false, position: 'center' })


ReactDOM.render(
    <Provider store={store}>
        {/*持久化处理*/}
        <PersistGate persistor={persistor}>
            {/* <Router history={history}> */}
            <SyncRouter />
            {/* </Router> */}
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);