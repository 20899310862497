import {CurrentCustomerIsBindWexin, GetCustomerInfo, loginjson, SignOut} from "../api/login";
import {Toast} from "antd-mobile";
import {baseUrl} from "../api/request";

interface userTypes {
    AvatarUrl?: string,
    Nickname?: string,
    PhoneNumber?: string,
    Id?: number,
    Point?: number,
    OpenId?: string,
    HasReceivedRedEnvelope?: boolean,
    ShippingAddress_Id?: number,
    SupportCashOnDelivery?: boolean
}

export interface loginProps {
    AccessToken?: string,
    AccessTokenNames?: string,
    CustomerInfo?: userTypes,
    isNight?: boolean,
    openDev: boolean
    openWater: boolean
}

const defaultUser: loginProps = {
    isNight: false,
    openDev: false,
    openWater: false,
}

interface actionType {
    type: string,
    payload: any,
}

export default function (state: loginProps = defaultUser, action: any) {
    switch (action.type) {
        case 'login/set':
            return Object.assign({}, state, action.payload);
        case 'login/out':
            return Object.assign({}, defaultUser);
        // 微信登陆
        case 'login/wx':
            return Object.assign({}, state, action.payload);
        case 'setting/night':
            return Object.assign({}, state, {
                isNight: action.payload
            });
        case 'setting/dev':
            return Object.assign({}, state, {
                openDev: !state.openDev
            });
        case 'setting/water':
            return Object.assign({}, state, {
                openDev: !state.openWater
            });
        default:
            return state
    }
}

export function checkNight() {
    return function (dispatch: any) {
        dispatch({
            type: "setting/night",
        })
    }
}

export function switchNight() {
    return function (dispatch: any) {

    }
}

export function exitLogin() {
    return function (dispatch: any) {
        dispatch({type: 'login/out'});
        dispatch({type: 'cart/reset'});
        window.localStorage.clear();
    }
}

export function wxLogin() {
    return (dispatch: any) => {
        let host = baseUrl;
        CurrentCustomerIsBindWexin().then(res => {
            GetCustomerInfo().then(userRes => {
                dispatch({type: 'login/wx', payload: {CustomerInfo: userRes.DataValue}})
            })
        }).catch(err => {
            if (err.data.DataValue < 3) {
                window.location.href = `${host}/OAuth2/WeiXinLogin?&urlReferrer=${escape(window.location.href)}`
            }
        })
    }
}

// export function checkAppLogin() {
//     GetCustomerInfo().then(userRes => {
//         // dispatch({ type: 'login/wx', payload: { CustomerInfo: userRes.DataValue } })
//     })
// }