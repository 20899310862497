import { request, prefix } from "./request";

// 获取产品列表
export function seachProducts(data: any) {
  return request({
    url: prefix + "SeachProducts?v=" + new Date().getTime(),
    method: "post",
    data,
    showLoading: true,
  });
}

// 获取产品列表并获取筛选项
export function SeachProductsAndFilterOptions(data: any) {
  return request({
    url: prefix + "SeachProductsAndFilterOptions?v=" + new Date().getTime(),
    method: "post",
    data,
    showLoading: true,
  });
}

// 获取未读消息数量
export function CustomerUnreadMessageTotal() {
  return request({
    url: prefix + "CustomerUnreadMessageTotal",
    method: "post",
  });
}

// 获取单个分类详细信息（含子分类
export function GetCompleteCategoryInfo(data: any) {
  return request({
    url: prefix + "GetCompleteCategoryInfo?v=" + new Date().getTime(),
    method: "post",
    data,
    showLoading: true,
  });
}

// 获取某产品的相关产品
export function GetRelatedProducts(data: any) {
  return request({
    url: "/API/Product/GetRelatedProducts",
    method: "post",
    data,
    showLoading: true,
  });
}

// 获取热门搜索关键字
export function GetHotSeachKey() {
  return request({
    url: prefix + "GetHotSeachKey?v=" + new Date().getTime(),
    method: "post",
    showLoading: true,
  });
}

// 获取产品详情
export function getProductInfo(data: any) {
  return request({
    url: prefix + "GetProductInfo",
    method: "post",
    data,
  });
}

// 获取产品列表并获取筛选项
export function seachProductsAndFilterOptions(data: any) {
  return request({
    url: prefix + "SeachProductsAndFilterOptions",
    method: "post",
    data,
  });
}

// 图片组
export function getImageGroups(data: any) {
  return request({
    url: prefix + "GetImageGroups",
    method: "post",
    data,
  });
}

// 获取产品详情（适合V2.4.0 以上版本，H5, 小程序）
export function GetProductDetailV240(data: any) {
  return request({
    url: "/API/Product/GetProductDetailV240",
    method: "post",
    data,
  });
}

// 获取商城设置
export function GetStoreSetting(showLoading?: boolean) {
  return request({
    url: prefix + "/GetStoreSetting",
    method: "post",
    showLoading: showLoading,
  });
}

// 获取客户浏览记录
export function GetCustomerFootPrint(data: any) {
  return request({
    url: prefix + "/GetCustomerFootPrint",
    method: "post",
    data,
    showLoading: true,
  });
}

// 获取子分类集合
export function GetProductCategory(data: any) {
  return request({
    url: prefix + "/GetProductCategory",
    method: "post",
    data,
    showLoading: true,
  });
}

//  添加客户收货地址
export function AddCustomerShippingAddress(data: any) {
  return request({
    url: prefix + "AddCustomerShippingAddress",
    method: "post",
    data,
    showLoading: true,
  });
}

//  修改收货地址
export function UpdateCustomerShippingAddress(data: any) {
  return request({
    url: prefix + "UpdateCustomerShippingAddress",
    method: "post",
    data,
    showLoading: true,
  });
}

//  删除收货地址
export function RevmoveCustomerShippingAddress(data: any) {
  return request({
    url: prefix + "RevmoveCustomerShippingAddress",
    method: "post",
    data,
    showLoading: true,
  });
}

//  获取收货地址详情
export function GetCustomerSingleShippingAddressDetailInfo(data: any) {
  return request({
    url: prefix + "GetCustomerSingleShippingAddressDetailInfo",
    method: "post",
    data,
    showLoading: true,
  });
}

// 给出客户收货地址
export function GetCustomerShippingAddress() {
  return request({
    url: prefix + "GetCustomerShippingAddress",
    method: "post",
  });
}

//  给出客户默认收货地址
export function GetCustomerDefaultShippingAddress() {
  return request({
    url: prefix + "GetCustomerDefaultShippingAddress",
    method: "post",
  });
}

//
export function FootPrint(data: any) {
  return request({
    url: "/API/Customer/FootPrint",
    method: "post",
    data,
    isForm: true,
    slience: true,
  });
}

//保存客户个人资料信息
export function SaveCustomerProfile(data: any) {
  return request({
    url: "/API/Customer/SaveCustomerProfile",
    method: "post",
    data,
  });
}

//保存客户的头像
export function SaveCustomerAvatar(data: any) {
  return request({
    url: "/API/Customer/SaveCustomerAvatar",
    method: "post",
    data,
  });
}

export function BatchUploadImageReturnId(data: any) {
  return request({
    url: prefix + "BatchUploadImageReturnId",
    method: "post",
    data,
    // isFile: true,
    isFile: true,
  });
}

//
export function GetCurrentCustomer() {
  return request({
    url: "/API/Customer/GetCurrentCustomer",
    method: "post",
    slience: true,
  });
}

//给出可以购买的会员卡列表
export function GetCanBuyMemberList() {
  return request({
    url: "/API/ServicesOtherPlatforms/GetCanBuyMemberList",
    method: "post",
  });
}

//给出会员卡信息
export function GetMemberInfoById() {
  return request({
    url: "/API/ServicesOtherPlatforms/GetMemberInfoById",
    method: "post",
  });
}

//给出的会员卡购买记录列表
export function GetMemberBuyRecordsList() {
  return request({
    url: "/API/ServicesOtherPlatforms/GetMemberBuyRecordsList",
    method: "post",
  });
}

//给出会员卡购买记录信息
export function GetMemberBuyRecordsById() {
  return request({
    url: "/API/ServicesOtherPlatforms/GetMemberBuyRecordsById",
    method: "post",
  });
}

//购买会员（返回订单号用于支付操作
export function BuyMember(data: any) {
  return request({
    url: "/API/ServicesOtherPlatforms/BuyMember",
    method: "post",
    data,
  });
}
//批量添加订单产品评价/追评
export function BatchAddOrderProductReview(data: any) {
  return request({
    url: "/API/Order/BatchAddOrderProductReview",
    method: "post",
    data,
  });
}
