/**
 * @By WebStorm
 * @Description: 购物车的Redux
 * @author cmk
 * @date 2022/1/16 14:02
 */
import {
    AddShoppingCart,
    getCustomerShoppingCart, RemoveShoppingCartItem,
    UpdateShoppingCartItemQuantity,
    UpdateShoppingCartItemQuantityReturnResult,
    UpdateShoppingCartItemSelected,
    UpdateShoppingCartItemSelectedAndReturnResult
} from '../api/cart'
import { Cart, Activity, StatisticsInfo } from '../schemas/cart'
import { ShoppingItemsToWishlist } from "../api/collecton";

export interface CartInfo {
    cartList: Cart[],
    isSelectAll: boolean,
    StatisticsInfo: StatisticsInfo,
    FreeShippingAmount: number,
    Actives: Activity[],
    cartNum: number,
    selectNum: number,
}

const defaultCart: CartInfo = {
    cartList: [],
    selectNum: 0,
    Actives: [],
    cartNum: 0,
    FreeShippingAmount: 99,
    isSelectAll: false,
    StatisticsInfo: {}
};

export default function (state = defaultCart, action?: any) {
    switch (action.type) {
        case 'cart/reset':
            return JSON.parse(JSON.stringify(defaultCart));
        case 'cart/setList':
            let num1 = 0;
            let num2 = 0;
            // selectNum
            let selectAll = true;
            //     action.payload.cartList.every((e: Cart) => {
            //     return e.HasBuy && e.Selected === true;
            // })
            action.payload.cartList.forEach((e: Cart) => {
                if (e.HasBuy) {
                    // @ts-ignore
                    if (e.Selected) {
                        // @ts-ignore
                        num1 = num1 + e.Quantity
                        // num2 = num2 + 1
                    } else {
                        selectAll = false;
                    }
                }
            })
            return Object.assign({}, state, action.payload, {
                isSelectAll: selectAll,
                selectNum: num1,
                cartNum: num1
            })
        case 'cart/setInfo':
            return Object.assign({}, state, action.payload)
        case 'cart/setAllNum':
            return Object.assign({}, state, {
                cartNum: action.payload
            })
        default:
            return state
    }
}

// 获取购物车列表还有计算统计信息
export function getCart() {
    return function (dispatch: any) {
        getCustomerShoppingCart().then(res => {
            dispatch({
                type: 'cart/setList',
                payload: {
                    cartList: res.DataValue.Items
                }
            })
            dispatch({
                type: 'cart/setInfo',
                payload: {
                    Actives: res.DataValue.Actives,
                    FreeShippingAmount: res.DataValue.FreeShippingAmount,
                    StatisticsInfo: res.DataValue.StatisticsInfo
                }
            })
        })
    }
}

// 设置购物车数量
export function setProductNum(id: number, num: number, cartList: Cart[]) {
    return (dispatch: any) => {
        let ids: string = '';
        let newList = JSON.parse(JSON.stringify(cartList))
        if (newList) {
            newList.forEach((e: Cart) => {
                if (e.Id === id) {
                    e.Quantity = num;
                }
            })
        }
        UpdateShoppingCartItemQuantity({ shoppCartItemId: id, quantity: num }).then(res => {
            dispatch({
                type: 'cart/setList',
                payload: {
                    cartList: newList
                }
            })
        })
    }
}

// 设置购物车数量 购物车中
export function setProductNumInCart(id: number, num: number, cartList: Cart[]) {
    return (dispatch: any) => {
        let ids: string = '';
        let newList = JSON.parse(JSON.stringify(cartList))
        if (newList) {
            newList.forEach((e: Cart) => {
                if (e.Id === id) {
                    e.Quantity = num;
                }
            })
        }
        UpdateShoppingCartItemQuantityReturnResult({ shoppCartItemId: id, quantity: num }).then(res => {
            dispatch({
                type: 'cart/setList',
                payload: {
                    cartList: newList
                }
            })
            dispatch({
                type: 'cart/setInfo',
                payload: {
                    StatisticsInfo: res.DataValue
                }
            })
        })
    }
}

// 选中单个购物车产品
export function selectSingleProduct(id: number, status: boolean, all: Cart[]) {
    let newStatus = !status
    return (dispatch: any) => {
        let ids: string = '';
        let newList = JSON.parse(JSON.stringify(all))
        if (newList) {
            newList.forEach((e: Cart) => {
                if (e.Id === id) {
                    e.Selected = newStatus
                }
            })
        }
        UpdateShoppingCartItemSelectedAndReturnResult({ shoppCartItemIds: id, selected: newStatus }).then(res => {
            dispatch({
                type: 'cart/setList',
                payload: {
                    cartList: newList
                }
            })
            dispatch({
                type: 'cart/setInfo',
                payload: {
                    StatisticsInfo: res.DataValue
                }
            })
        })
    }
}

// 选中购物车所有商品
export function slelectAllProducts(newStatus: boolean, all: Cart[]) {
    return (dispatch: any) => {
        let ids: string = '';
        let newList = JSON.parse(JSON.stringify(all));
        if (newList) {
            newList.forEach((e: Cart) => {
                if (e.HasBuy) {
                    ids += e.Id + ',';
                    e.Selected = newStatus
                }
            })
        }
        UpdateShoppingCartItemSelectedAndReturnResult({ shoppCartItemIds: ids, selected: newStatus }).then(res => {
            dispatch({
                type: 'cart/setList',
                payload: {
                    cartList: newList
                }
            })
            dispatch({
                type: 'cart/setInfo',
                payload: {
                    StatisticsInfo: res.DataValue
                }
            })
        })
    }
}

export function removeProduct(cartList: Cart[]) {
    let ids = '';
    let list = JSON.parse(JSON.stringify(cartList));
    list.forEach((e: Cart) => {
        if (e.Selected) {
            ids += e.Id + ','
        }
    })
    return function (dispatch: any) {
        RemoveShoppingCartItem({
            shoppCartItemIds: ids
        }).then(res1 => {
            getCustomerShoppingCart().then(res => {
                dispatch({
                    type: 'cart/setList',
                    payload: {
                        cartList: res.DataValue.Items
                    }
                })
                dispatch({
                    type: 'cart/setInfo',
                    payload: {
                        Actives: res.DataValue.Actives,
                        FreeShippingAmount: res.DataValue.FreeShippingAmount,
                        StatisticsInfo: res.DataValue.StatisticsInfo
                    }
                })
            })
        })
    }
}

export function removeAssignProduct(cartList: Cart[], id: any) {
    return function (dispatch: any) {
        RemoveShoppingCartItem({
            shoppCartItemIds: id
        }).then(res1 => {
            getCustomerShoppingCart().then(res => {
                dispatch({
                    type: 'cart/setList',
                    payload: {
                        cartList: res.DataValue.Items
                    }
                })
                dispatch({
                    type: 'cart/setInfo',
                    payload: {
                        Actives: res.DataValue.Actives,
                        FreeShippingAmount: res.DataValue.FreeShippingAmount,
                        StatisticsInfo: res.DataValue.StatisticsInfo
                    }
                })
            })
        })
    }
}

// 清除失效商品
export function removeLoseProduct(cartList: Cart[]) {
    let ids = '';
    let list = JSON.parse(JSON.stringify(cartList));
    list.forEach((e: Cart) => {
        if (!e.HasBuy) {
            ids += e.Id + ','
        }
    })
    return function (dispatch: any) {
        RemoveShoppingCartItem({
            shoppCartItemIds: ids
        }).then(res1 => {
            getCustomerShoppingCart().then(res => {
                dispatch({
                    type: 'cart/setList',
                    payload: {
                        cartList: res.DataValue.Items
                    }
                })
                dispatch({
                    type: 'cart/setInfo',
                    payload: {
                        Actives: res.DataValue.Actives,
                        FreeShippingAmount: res.DataValue.FreeShippingAmount,
                        StatisticsInfo: res.DataValue.StatisticsInfo
                    }
                })
            })
        })
    }
}

// 其他页面加入购物车，并改变数量
export function AddShoppingCartInPage(json: string) {
    return function (dispatch: any) {
        AddShoppingCart({ productInfo: json }).then(res => {
            getCustomerShoppingCart().then(res => {
                dispatch({
                    type: 'cart/setList',
                    payload: {
                        cartList: res.DataValue.Items
                    }
                })
                dispatch({
                    type: 'cart/setInfo',
                    payload: {
                        Actives: res.DataValue.Actives,
                        FreeShippingAmount: res.DataValue.FreeShippingAmount,
                        StatisticsInfo: res.DataValue.StatisticsInfo
                    }
                })
            })
        })
    }
}

export function cartToCollection(cartList: Cart[]) {
    let ids = '';
    let list = JSON.parse(JSON.stringify(cartList));
    list.forEach((e: Cart) => {
        if (e.Selected) {
            ids += e.Id + ','
        }
    })
    return function (dispatch: any) {
        // @ts-ignore
        ShoppingItemsToWishlist({ itemIds: ids }).then(res1 => {
            getCustomerShoppingCart().then(res => {
                dispatch({
                    type: 'cart/setList',
                    payload: {
                        cartList: res.DataValue.Items
                    }
                })
                dispatch({
                    type: 'cart/setInfo',
                    payload: {
                        Actives: res.DataValue.Actives,
                        FreeShippingAmount: res.DataValue.FreeShippingAmount,
                        StatisticsInfo: res.DataValue.StatisticsInfo
                    }
                })
            })
        })
    }
}