/**
 * @By WebStorm
 * @Description:
 * @author cmk
 * @date 2022/1/17 17:37
 */
import {
    AddShoppingCart,
    getCustomerShoppingCart, RemoveShoppingCartItem,
    UpdateShoppingCartItemQuantity,
    UpdateShoppingCartItemQuantityReturnResult,
    UpdateShoppingCartItemSelected,
    UpdateShoppingCartItemSelectedAndReturnResult
} from '../api/cart'
import {Cart, Activity, StatisticsInfo} from '../schemas/cart'
import {ShoppingItemsToWishlist} from "../api/collecton";
import {CustomerUnreadMessageTotal, GetStoreSetting} from "../api/commom";

interface envType{
    isWechat:boolean,
    isApp:boolean
}

export interface HomeProps {
    index:number,
    SearchGuideWords:string
    msgNum:number,
    envType:envType
}

const defaultCart: HomeProps = {
    index:0,
    SearchGuideWords:'茶七出好物',
    msgNum:0,
    envType:{
        isWechat:false,
        isApp:false,
    }
};


export default function (state = defaultCart, action?: any) {
    switch (action.type) {
        case 'home/switch':
            return Object.assign({},state,{
                index:action.payload
            })
        case 'home/setEnv':
            return Object.assign({},state,{
                index:action.payload
            })
        case 'home/setting':
            return Object.assign({},state,{
                SearchGuideWords:action.action
            })
        case 'home/setMsgNum':
            return Object.assign({},state,{
                msgNum:action.action
            })
        default :
            return state
    }
}


export function getSetting(){
    return function (dispatch:any){
        GetStoreSetting().then(res=>{
            dispatch({
                type:'home/setting',
                action:res.DataValue.SearchGuideWords
            })
        })
        dispatch({
            type:'home/setEnv',
            action:{
                isApp:false,
                isWechat:navigator.userAgent.toLowerCase().indexOf('micromessenger') >=0,
            }
        })
    }
}

export function CustomerUnreadMessageTotals(){
    return function (dispatch:any){
        // @ts-ignore
        CustomerUnreadMessageTotal().then(res=>{
            dispatch({
                type:'home/setMsgNum',
                action:res.DataValue
            })
        })
    }
}
