/**
 * @By WebStorm
 * @Description: 收藏夹的API
 * @author cmk
 * @date 2022/1/16 20:09
 */

import {request, prefix} from "./request";

export function ShoppingItemsToWishlist(data: any) {
    return request({
        url: prefix + "ShoppingItemsToWishlist",
        method: 'post',
        data,
        showLoading: true
    })
}

export function GetCustomerWishlist(data: any) {
    return request({
        url: prefix + "GetCustomerWishlist",
        method: 'post',
        data,
        showLoading: true
    })
}
