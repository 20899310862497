import { QueryGoods } from './../schemas/goods';
/**
 * @By WebStorm
 * @Description: 一些常用工具类
 * @author cmk
 * @date 2022/1/18 19:24
 */

import { useCallback, useEffect, useState } from "react";
import { Toast } from "antd-mobile";
import { history } from "../routes/history";
import { store } from '../redux'
import { GetCurrentCustomer } from "../api/commom";
import { rejects } from "assert";
import { GetCustomerInfo } from "../api/login";
import { globalSub } from "../App";
export const lazyLoadRate = 50;
// 滚到顶部
export const scrollToTop = () => {
    // 距离顶部的距离
    const gap = document.documentElement.scrollTop || document.body.scrollTop;
    if (gap > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, gap - gap / 10);
    }
};

// 计算金额
export const countAmount = (val1: number, val2: number): number => {
    let count: number = Number((((parseFloat(String(val1)) * 1000) - (parseFloat(String(val2)) * 1000)) / 1000).toFixed(2))
    count = Number.isInteger(parseFloat(String(count))) ? Number(count) : count;
    return count;
}


export const toLogins = () => {
    setTimeout(() => {
        globalSub.emit('redirect', '/login?redirct=' + encodeURIComponent(window.location.pathname))
    }, 100);
}

// 计算金额(柯里化计算）
export function countAmountCurrys() {
    // 保存第一次调用的参数，因为柯里化的最终目的都是把所有的入参都放到第一个参数之中
    let args = Array.prototype.slice.call(arguments);
    // 抛出adder来收集后续的参数，并保存到第一个参数中
    let adder = function () {
        args.push(...arguments)
        return adder
    }
    // 利用隐式转换，来计算
    adder.toString = function () {
        return args.reduce(function (a, b) {
            return countAmount(a, b)
        })
    }
    return adder
}

// 计算金额(柯里化计算）
export function countAmountCurrys1() {
    let len = Array.prototype.slice.call(arguments);
    let all = len.reduce((a, b) => {
        return countAmount(a, b)
    })
}

// 简单数组去重
export const unique = (arr: any) => {
    return arr.reduce((prev: any, cur: any) => prev.includes(cur) ? prev : [...prev, cur], []);
}

// 强制触发渲染
export const useUpdate = () => {
    const [_, setState] = useState(0);
    return useCallback(() => {
        setState((num: number): number => num + 1);
    }, []);
};
let goonRe = true;
export const useChangeVisible = (watch: any,) => {
    const changes = () => {
        if (document.visibilityState === 'visible' && goonRe) {
            watch()
        }
    }
    window.addEventListener('visibilitychange', changes, false)
    useEffect(() => {
        goonRe = true;
        return () => {
            goonRe = false;
            window.removeEventListener('visibilitychange', changes, false)
        }
    }, [])
}

let debounceTimer: any = null;
export const debounce = (fn: () => void, delay: number = 1000) => {
    return () => {
        if (debounceTimer) {
            clearTimeout(debounceTimer)
        }
        debounceTimer = setTimeout(fn, delay)
    }
}

/**
 * 获取当前时间
 * @param hasHMS:格式化
 */
export const formatDate = (dates: string, hasHMS = false): string | number => {
    const date = new Date(Number(dates));
    let Y = date.getFullYear();
    let M = date.getMonth() + 1;
    let D = date.getDate();
    let h: string | number, m: string | number, s: string | number;
    if (hasHMS === true) {
        h = date.getHours();
        m = date.getMinutes();
        s = date.getMinutes();
    }
    // @ts-ignore
    return Y + "-" + (M < 10 ? "0" + M : M) + "-" + (D < 10 ? "0" + D : D) + "  " + (hasHMS ? (h < 10 ? " 0" + h : h) + ":" + (m < 10 ? ("0" + m) : m) + ":" + (s < 10 ? "0" + s : s) : "");
};

// 加载七鱼客服
export const loadQykf = () => {
    let user = store.getState()?.login?.CustomerInfo;
    GetCurrentCustomer().then(res => {

        // @ts-ignore
        if (window.ysf && user) {
            // @ts-ignore
            ysf.config({
                "uid": res.DataValue?.OtherPlatformId || '',
                "data": JSON.stringify([
                    { "key": "real_name", "value": res.DataValue.OtherPlatformName },
                    { "key": "mobile_phone", "hidden": true },
                    { "key": "email", "value": "" }
                ])
            });
        }
    });
}


export const sleep = (time: number) =>
    new Promise(resolve => setTimeout(resolve, time));

// 验证是否绑定手机号
export const checkPhone = () => {
    return new Promise((resolve, reject) => {
        if (!store.getState().login.CustomerInfo?.PhoneNumber) {
            Toast.show({
                content: "为了您的用户体验，请绑定手机号",
                afterClose: () => {
                    globalSub.emit('redirect', '/bindPhone?redirct=' + encodeURIComponent(window.location.pathname))
                    reject();
                }
            })
        } else {
            resolve(true);
        }
    })
}

interface devTypes {
    isAndroid: boolean,
    isIos: boolean,
    isTea7App: boolean
}

export const checkDev: devTypes = {
    isAndroid: navigator.userAgent.indexOf("T7appAndroidV2") >= 0,
    isIos: navigator.userAgent.indexOf("T7appIOSV2") >= 0,
    isTea7App: navigator.userAgent.indexOf("T7appIOSV2") >= 0 || navigator.userAgent.indexOf("T7appAndroidV2") >= 0
}


    export class Subscriptions {
        private list: any;

        constructor() {
            this.list = {}
        }

        on(name: string, fn: any) {
            if (this.list[name]) {
                return
            }
            this.list[name] = [fn]
        }

        emit(name: string, ...args: any) {
            if (this.list[name]) {
                this.list[name].forEach((event: any) => {
                    event(...args)
                })
            }
        }

    }

export interface ShartType {
    // 分享标题 默认当前页面标题
    title?: string,
    // 分享介绍 
    desc?: string,
    // 分享链接 默认当前页面
    link?: string,
    // 分享图片地址
    imgUrl?: string,
    // 成功回调
    success?: any

}
export function setShare(config?: ShartType) {
    // return
    // @ts-ignore
    if (!!window.wx) {
        let props: ShartType = {
            title: window.document.title,
            desc: "",
            link: window.document.URL,
            imgUrl: 'http://m.tea7.com/Themes/Mobile/images/webwxgeticon.jpg',
            success: () => {
                console.log('设置成功');
            }
        }
        if (!!config) {
            Object.assign(props, config)
        }
        // @ts-ignore
        window.wx.ready(() => {
            // @ts-ignore
            window.wx.updateAppMessageShareData(props)
            // @ts-ignore
            window.wx.updateTimelineShareData(props)
        })
    }
}

export interface qyGoods {
    picture: string,
    title: string,
    desc?: string,
    price?: string,
    url?: string,
    show: 1
}
export function toQiyuGoods(config: qyGoods) {
    loadQykf();
    var data = config
    // @ts-ignore
    window.ysf('product', data);
    // window.parent.postMessage(data, '*'); // 将数据用postMessage的方式发送到七鱼
}
export interface qyOrder {
    show: 1,
    // 商品的缩略图地址
    picture: string,
    // 商品的展示标题
    title: string,

}
export function toQiyuOrder(config: any) {
    loadQykf();
    var data = Object.assign({}, config);
    // @ts-ignore
    window.ysf('product', data);
}