/**
 * @By WebStorm
 * @Description:
 * @author cmk
 * @date 2022/2/11 18:17
 */

import {
  AliPayH5Payment,
  WeixinH5Payment,
  WeixinJSPayment,
} from "../../../api/order";
import { store } from "../../../redux";
import { ResultData } from "../../../schemas/commom";

interface payItemType {
  // 支付方式
  name: string;
  // 支付参数
  key: string;
  // 支付请求方法
  payFun?: (data: any) => Promise<ResultData<any>>;
}

export const payType = {
  // 货到付款
  HUODAOFUKUANG: "Payments.CashOnDelivery",
  // 微信JS
  WEIXIN_JS: "Payments.WeixinJsApiPay",
  // 微信H5
  WEIXIN_H5: "Payments.WeixinWap",
  // 支付宝H5
  ZHIFUBAO_H5: "Payments.AliPayWap",
};

// 支付宝支付
const ZfbPay = {
  name: "支付宝",
  key: payType["ZHIFUBAO_H5"],
  payFun: AliPayH5Payment,
};

// 微信内H5支付
const Wxh5Pay = {
  name: "微信支付",
  key: payType["WEIXIN_JS"],
  payFun: WeixinJSPayment,
};

// 普通H5微信支付
const h5Pay = {
  name: "微信支付",
  key: payType["WEIXIN_H5"],
  payFun: WeixinH5Payment,
};

export const payTypeList = () => {
  let list: payItemType[] = [
    {
      name: "货到付款",
      key: payType["HUODAOFUKUANG"],
    },
  ];
  // 如果是微信则调用微信JS支付
  if (navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1) {
    list.unshift(Wxh5Pay);
  } else {
    list.unshift(ZfbPay);
    list.unshift(h5Pay);
  }
  return list;
};

export const vipPayList = () => {
  let list: payItemType[] = [];
  // 如果是微信则调用微信JS支付
  if (navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1) {
    list.unshift(Wxh5Pay);
  } else {
    list.unshift(ZfbPay);
    list.unshift(h5Pay);
  }
  return list
};
